const input = {
    username: 'Nombre de usuario',
    password: 'Contraseña',
    email: 'Correo electrónico',
    phone_number: 'Número de teléfono',
    confirm_password: 'Confirmar contraseña',
    search_region_code: 'Código de región de búsqueda',
    backup_code: 'Código de respaldo',
};
export default Object.freeze(input);
